import React from "react"

const ActivcareProtection = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 92.16 116.63"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <circle
              className="cls-1"
              cx="46.08"
              cy="70.55"
              r="45.08"
              fill="none"
              stroke="#8a4e9e"
              strokeDasharray="0 0 11 11"
              strokeMiterlimit="10"
              strokeWidth="2px"
            />
            <circle
              className="cls-4"
              cx="46.08"
              cy="70.55"
              r="37.18"
              strokeWidth="0px"
              fill="#8a4e9e"
            />
          </g>
          <g>
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M25.31,71.85s.06-.06.07-.09c.38-1.07,1.03-1.54,2.15-1.54,4.03,0,8.05,0,12.08,0,1.01,0,1.95.25,2.81.78,1.42.89,2.97,1.29,4.65,1.29,5.78-.02,11.56,0,17.35,0,1.61,0,2.41.81,2.41,2.42,0,2.13,0,4.27,0,6.4,0,1.39-.86,2.26-2.24,2.26-12.33,0-24.67,0-37,0-1.17,0-1.75-.43-2.27-1.7,0-3.27,0-6.54,0-9.81ZM46.03,81.97c6.12,0,12.24,0,18.36,0,.88,0,1.06-.19,1.06-1.08,0-2.07,0-4.13,0-6.2,0-.85-.19-1.04-1.06-1.04-5.76,0-11.51-.01-17.27,0-1.96,0-3.77-.45-5.42-1.49-.65-.41-1.35-.58-2.12-.58-3.99,0-7.97,0-11.96,0-.72,0-.93.22-.93.95,0,2.8,0,5.59,0,8.39,0,.85.19,1.04,1.05,1.04,6.09,0,12.19,0,18.28,0Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M55.88,70.89c-2.97,0-5.95,0-8.92,0-1.92,0-3.56-.65-4.93-1.99-.66-.65-1.44-1.09-2.33-1.34-.12-.03-.32,0-.41.09-.76.69-1.64,1.13-2.66,1.14-1.89.03-3.79.09-5.67-.04-2.69-.18-4.77-2.44-4.93-5.15-.16-2.71,1.65-5.14,4.28-5.71.42-.09.85-.14,1.28-.15,2.01-.02,4.03-.01,6.04,0,1.69,0,2.86,1.13,2.9,2.82,0,.27.12.36.33.47.71.37,1.38.29,2.04-.11.44-.27.87-.54,1.28-.85.85-.64,1.8-.95,2.87-.95,1.77,0,3.54.02,5.31,0,2.39-.03,4.71.32,6.95,1.15,1.87.69,3.82,1,5.8.93,1.2-.04,1.75.72,1.73,1.73-.05,2.13-.01,4.27-.02,6.4,0,1.01-.54,1.55-1.55,1.55-3.13,0-6.27,0-9.4,0ZM65.43,64.64v-2.03c-.12,0-.23-.02-.33-.02-2.29.03-4.51-.37-6.66-1.16-1.35-.49-2.75-.83-4.19-.86-2.38-.06-4.75-.04-7.13-.07-.8,0-1.5.24-2.15.71-.6.43-1.23.83-1.89,1.16-.68.34-1.42.43-2.16.15-.43-.16-.85-.33-1.28-.5-.31-.12-.47-.35-.47-.69,0-.27,0-.54,0-.81-.04-.81-.59-1.38-1.4-1.39-2.11-.01-4.22-.05-6.32,0-2.68.07-4.54,2.55-3.92,5.14.46,1.89,2.11,3.15,4.19,3.16,1.47.01,2.95,0,4.42,0,1.57,0,2.52-.7,2.99-2.21.14-.44.46-.64.86-.54.39.1.59.45.49.89-.05.23-.13.46-.2.7,1.05.33,1.95.86,2.71,1.61,1.11,1.1,2.45,1.62,4.02,1.62,6-.01,12,0,18,0,.14,0,.29,0,.44,0v-3.45c-.19,0-.35,0-.52,0-2.35,0-4.7,0-7.05,0-1.36,0-2.69-.2-3.96-.71-1.25-.5-2.54-.71-3.88-.69-.82.01-1.65.01-2.47,0-.55,0-.86-.39-.73-.87.11-.39.41-.51.78-.51.82,0,1.65,0,2.47,0,1.49-.02,2.93.22,4.32.78,1.01.41,2.07.61,3.16.61,2.55,0,5.11,0,7.66,0,.07,0,.13-.01.24-.02Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M59.9,80.58c-.37,0-.69-.34-.68-.71,0-.38.33-.68.72-.66.38.01.65.3.66.68,0,.38-.31.7-.69.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M57.83,79.9c0,.38-.34.69-.71.68-.37-.01-.68-.35-.67-.72.02-.37.32-.65.69-.65.39,0,.69.31.68.69Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M35.69,74.35c0,.38-.3.69-.69.69-.37,0-.67-.29-.68-.65-.01-.38.29-.7.67-.71.38-.01.7.3.71.68Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M35.69,79.88c0,.37-.32.7-.69.7-.37,0-.69-.32-.69-.7,0-.37.29-.66.66-.67.39-.01.7.28.71.67Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M32.25,80.58c-.37,0-.69-.31-.69-.69,0-.38.27-.67.65-.68.39-.02.71.28.72.66.01.37-.31.71-.68.71Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M57.12,77.81c-.38-.01-.68-.34-.67-.72.02-.38.34-.67.73-.65.37.02.65.32.65.69,0,.38-.33.69-.71.68Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M63.36,79.91c0,.38-.34.69-.71.67-.36-.02-.66-.33-.66-.69,0-.38.31-.68.7-.68.39,0,.68.3.67.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M35.69,77.1c.01.38-.3.7-.68.71-.38,0-.7-.31-.7-.69,0-.37.28-.66.66-.68.39-.02.71.28.72.66Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M38.45,77.14c0,.38-.33.69-.71.67-.36-.02-.66-.32-.66-.68,0-.38.31-.69.7-.68.39,0,.69.31.68.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M29.47,80.58c-.38,0-.69-.31-.68-.7,0-.38.28-.66.66-.67.39-.01.71.29.71.67,0,.37-.32.7-.69.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M38.45,74.34c0,.39-.29.69-.68.69-.39,0-.7-.31-.69-.69,0-.36.3-.67.67-.68.38-.01.7.29.71.67Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M38.45,79.88c0,.38-.31.7-.69.7-.37,0-.69-.33-.69-.7,0-.37.3-.66.67-.67.39,0,.7.28.7.67Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M41.22,79.89c0,.38-.32.7-.7.69-.37-.01-.69-.35-.68-.72.02-.37.32-.65.69-.65.39,0,.69.29.69.69Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M55.06,77.12c0,.38-.33.69-.7.68-.38,0-.68-.33-.67-.71.01-.37.3-.65.68-.66.39,0,.7.3.7.68Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M51.6,80.58c-.38,0-.68-.32-.68-.7,0-.39.31-.68.7-.67.37.01.67.31.67.67,0,.37-.32.7-.69.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M55.07,79.9c-.01.37-.35.69-.72.68-.38-.02-.68-.35-.66-.73.02-.37.31-.65.69-.64.39,0,.7.32.69.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M40.52,77.81c-.37,0-.69-.34-.68-.71.02-.38.34-.67.73-.65.38.02.65.31.64.69,0,.39-.32.69-.7.68Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M32.24,77.81c-.38,0-.69-.3-.69-.69,0-.38.28-.66.65-.68.39-.02.71.28.72.66.01.37-.3.7-.68.71Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M52.3,77.11c0,.37-.31.7-.69.7-.37,0-.67-.29-.69-.65-.02-.4.26-.7.66-.71.39-.01.71.28.72.66Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M29.45,76.44c.39,0,.7.3.71.68,0,.37-.32.69-.7.69-.37,0-.66-.29-.67-.66-.01-.39.27-.7.67-.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M60.59,77.11c0,.38-.31.7-.69.7-.38,0-.7-.33-.69-.7,0-.36.3-.66.67-.67.39,0,.7.28.7.67Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M62.68,76.44c.39,0,.68.3.68.69,0,.37-.29.66-.66.68-.38.01-.7-.3-.71-.68,0-.38.31-.69.69-.69Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M41.22,74.33c0,.39-.28.7-.67.7-.39,0-.7-.3-.7-.68,0-.36.3-.67.66-.69.38-.02.7.28.71.67Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M48.85,80.58c-.38,0-.69-.31-.69-.69,0-.38.28-.67.65-.68.39-.01.71.28.72.66.01.37-.31.7-.68.71Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M32.22,75.03c-.39,0-.68-.31-.67-.71.01-.37.31-.66.68-.66.38,0,.7.32.69.69,0,.38-.32.68-.71.67Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M30.16,74.36c0,.38-.33.68-.71.67-.39-.01-.67-.32-.66-.71.01-.37.31-.66.68-.66.38,0,.7.33.69.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M43.3,76.44c.39,0,.69.3.68.69,0,.37-.29.67-.66.68-.38.01-.7-.3-.71-.68,0-.38.3-.69.69-.69Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M43.29,79.21c.39,0,.69.28.69.68,0,.38-.31.7-.69.69-.36,0-.67-.31-.68-.67-.01-.38.29-.7.68-.7Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M46.75,79.9c0,.38-.33.69-.71.68-.38-.02-.68-.34-.66-.73.02-.37.31-.65.69-.65.39,0,.69.31.68.69Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M48.16,77.13c0-.39.29-.69.68-.69.38,0,.7.31.69.69,0,.36-.31.67-.67.68-.38.01-.69-.29-.7-.68Z"
            />
            <path
              className="cls-3"
              strokeWidth="0px"
              fill="#fff"
              d="M46.08,76.44c.39,0,.68.31.67.7-.01.37-.31.66-.67.67-.38,0-.69-.3-.69-.69,0-.39.3-.69.69-.68Z"
            />
          </g>
        </g>
        <g>
          <circle
            className="cls-2"
            cx="46.08"
            cy="23.44"
            r="23.44"
            fill="#a1aab0"
            strokeWidth="0px"
          />
          <path
            className="cls-3"
            strokeWidth="0px"
            fill="#fff"
            d="M37.83,33.28h5.81v-16.72c0-.65.02-1.33.06-2.04l-4.13,3.45c-.17.15-.35.25-.53.3-.18.05-.35.08-.53.08-.26,0-.51-.06-.72-.17-.22-.11-.38-.24-.49-.39l-1.55-2.12,8.75-7.44h4.03v25.06h5.16v3.67h-15.87v-3.67Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ActivcareProtection
