import React from "react"

const QualityFillingLayers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 93.4 126.09"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <circle
              className="cls-2"
              fill="none"
              stroke="#8a4e9e"
              strokeDasharray="0 0 11 11"
              strokeMiterlimit="10"
              strokeWidth="2px"
              cx="46.7"
              cy="79.39"
              r="45.7"
            />
            <circle
              className="cls-5"
              strokeWidth="0px"
              fill="#8a4e9e"
              cx="46.7"
              cy="79.39"
              r="37.69"
            />
          </g>
          <g>
            <rect
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x="23.37"
              y="91.18"
              width="47.01"
              height="10.85"
              rx=".89"
              ry=".89"
            />
            <path
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              d="M23.37,92.55h0c0-.88.21-1.74.6-2.52l5.93-11.75c.35-.7,1.07-1.14,1.85-1.14h30.24c.78,0,1.5.44,1.85,1.14l5.93,11.75c.39.78.6,1.65.6,2.52h0"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="27.47"
              y1="94.13"
              x2="27.47"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="31.88"
              y1="88.28"
              x2="32.3"
              y2="88.28"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="41.73"
              y1="88.28"
              x2="42.16"
              y2="88.28"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="51.59"
              y1="88.28"
              x2="52.01"
              y2="88.28"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="61.44"
              y1="88.28"
              x2="61.86"
              y2="88.28"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="32.83"
              y1="85.47"
              x2="33.23"
              y2="85.47"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="42.06"
              y1="85.47"
              x2="42.46"
              y2="85.47"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="51.29"
              y1="85.47"
              x2="51.68"
              y2="85.47"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="60.51"
              y1="85.47"
              x2="60.91"
              y2="85.47"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="33.75"
              y1="82.56"
              x2="34.12"
              y2="82.56"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="42.37"
              y1="82.56"
              x2="42.74"
              y2="82.56"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="51"
              y1="82.56"
              x2="51.37"
              y2="82.56"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="59.63"
              y1="82.56"
              x2="60"
              y2="82.56"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="34.52"
              y1="79.93"
              x2="34.87"
              y2="79.93"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="42.64"
              y1="79.93"
              x2="42.99"
              y2="79.93"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="50.76"
              y1="79.93"
              x2="51.1"
              y2="79.93"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="58.87"
              y1="79.93"
              x2="59.22"
              y2="79.93"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="30.99"
              y1="94.13"
              x2="30.99"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="34.52"
              y1="94.13"
              x2="34.52"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="38.05"
              y1="94.13"
              x2="38.05"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="41.58"
              y1="94.13"
              x2="41.58"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="45.11"
              y1="94.13"
              x2="45.11"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="48.64"
              y1="94.13"
              x2="48.64"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="52.16"
              y1="94.13"
              x2="52.16"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="55.69"
              y1="94.13"
              x2="55.69"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="59.22"
              y1="94.13"
              x2="59.22"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="62.75"
              y1="94.13"
              x2="62.75"
              y2="99.08"
            />
            <line
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              x1="66.28"
              y1="94.13"
              x2="66.28"
              y2="99.08"
            />
            <path
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              d="M55.75,72.47c6.15-1.54,9.38-9.83,7.82-12.68-.98-1.79-6.25-3.44-11.25-2.9-3.19.34-5.34,1.29-6.34,1.82"
            />
            <path
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              d="M47.57,62.31s-1.98-.82-3.93-1.58c-2.25-.87-4.75-.99-7.03-.21-6.85,2.32-7.1,10.09-7.1,10.09,5.15-1.64,10.74,2.53,16.13,3.06,3.27.32,6.81-1.43,9.04-3.85.94-1.03,1.6-1.84,1.6-1.84"
            />
            <path
              className="cls-1"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".85px"
              fill="none"
              d="M36.12,66.39c4.87-1.35,7.55,1.17,14.9-.81,4.95-1.33,9.8-3.46,16.74-8.83"
            />
          </g>
        </g>
        <g>
          <circle
            className="cls-3"
            fill="#a1aab0"
            strokeWidth="0px"
            cx="47.63"
            cy="23.44"
            r="23.44"
          />
          <path
            className="cls-4"
            strokeWidth="0px"
            fill="#fff"
            d="M55.74,10.35c0,.7-.22,1.27-.66,1.71-.44.44-1.18.66-2.21.66h-7.9l-1.03,5.99c1.3-.26,2.48-.4,3.55-.4,1.51,0,2.83.23,3.98.68,1.14.45,2.11,1.07,2.89,1.86.78.79,1.37,1.72,1.77,2.79s.59,2.21.59,3.44c0,1.52-.27,2.91-.8,4.17-.54,1.26-1.28,2.33-2.23,3.22-.95.89-2.08,1.58-3.38,2.07-1.3.49-2.72.73-4.26.73-.9,0-1.75-.09-2.56-.28s-1.56-.43-2.27-.74c-.71-.31-1.36-.67-1.96-1.07-.6-.4-1.14-.83-1.62-1.28l1.51-2.08c.32-.45.74-.67,1.27-.67.33,0,.66.11,1,.32.34.21.73.44,1.18.69.45.25.97.48,1.57.69.59.21,1.32.32,2.16.32.9,0,1.69-.15,2.38-.44.69-.29,1.26-.7,1.72-1.22.46-.52.8-1.14,1.03-1.86.23-.72.35-1.5.35-2.35,0-1.56-.45-2.78-1.36-3.66-.91-.88-2.24-1.32-4-1.32-1.39,0-2.78.25-4.19.75l-3.05-.87,2.38-13.93h14.17v2.08Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default QualityFillingLayers
