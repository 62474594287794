import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import SEO from "../components/SEO/seo"
import Layout from "../zzz/layout"
import Inner from "../zzz/layout/pageInner/Inner"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Formik, Form, Field, ErrorMessage, getIn } from "formik"
import Logo from "../zzz/atoms/logo"
import { GatsbyImage } from "gatsby-plugin-image"
import SlumberlandCompetitionLogo from "../zzz/atoms/icons/slumberland-competition/slumberland-competition-logo"
import ActivcareProtection from "../zzz/atoms/icons/slumberland-competition/activcare-protection"
import CoolKnitFabric from "../zzz/atoms/icons/slumberland-competition/cool-knit-fabric"
import ActivecoilSpringSystem from "../zzz/atoms/icons/slumberland-competition/activecoil-spring-system"
import SurroundActivedgeSystem from "../zzz/atoms/icons/slumberland-competition/surround-activedge-system"
import QualityFillingLayers from "../zzz/atoms/icons/slumberland-competition/quality-filling-layers"
import HowToEnterTheCompetition from "../zzz/atoms/icons/slumberland-competition/how-to-enter-the-competition"
import ZeroOne from "../zzz/atoms/icons/slumberland-competition/01"
import StartTheQuiz from "../zzz/atoms/icons/slumberland-competition/start-the-quiz"
import ZeroTwo from "../zzz/atoms/icons/slumberland-competition/02"
import ZeroThree from "../zzz/atoms/icons/slumberland-competition/03"
import FillInForm from "../zzz/atoms/icons/slumberland-competition/fill-in-form"
import { saveSlumberlandCompetitionData } from "../lib/woocommApi"
import { getUser } from "../services/auth"

// export const onRenderBody = ({ setHeadComponents }) => {
//   setHeadComponents([
//     <link
//       key="Binjay"
//       rel="preload"
//       href="/fonts/Binjay.ttf"
//       as="font"
//       type="font/ttf"
//       crossOrigin="anonymous"
//     />,
//     <link
//       key="Engravers Gothic BT Font"
//       rel="preload"
//       href="/fonts/Engravers-Gothic-BT-Font.ttf"
//       as="font"
//       type="font/ttf"
//       crossOrigin="anonymous"
//     />,
//     <link
//       key="Lato-Black"
//       rel="preload"
//       href="/fonts/Lato-Black.ttf"
//       as="font"
//       type="font/ttf"
//       crossOrigin="anonymous"
//     />,
//     <link
//       key="Lato-Bold"
//       rel="preload"
//       href="/fonts/Lato-Bold.ttf"
//       as="font"
//       type="font/ttf"
//       crossOrigin="anonymous"
//     />,
//     <link
//       key="Lato-Light"
//       rel="preload"
//       href="/fonts/Lato-Light.ttf"
//       as="font"
//       type="font/ttf"
//       crossOrigin="anonymous"
//     />,
//     <link
//       key="Lato"
//       rel="preload"
//       href="/fonts/Lato-Regular.ttf"
//       as="font"
//       type="font/ttf"
//       crossOrigin="anonymous"
//     />,
//     <link
//       key="MyriadPro"
//       rel="preload"
//       href="/fonts/MyriadPro-Regular.otf"
//       as="font"
//       type="font/otf"
//       crossOrigin="anonymous"
//     />
//   ]);
// };

const SlumberlandCompetitionPage = ({ location }) => {
  const graphqlData = useStaticQuery(SLUMBERLAND_COMPETITION_PAGE_QUERY)
  const pageData = graphqlData.localWpGraphQlPages
  const title = pageData.title
  const content = pageData.content
  const seo = pageData.seo
  const pageId = pageData.pageId
  const [tabIndex, setTabIndex] = useState(0)

  const GoToNextTab = (targetTabIndex) => {
    setTabIndex(targetTabIndex)
    const form_element = document.getElementById("slumberland-competition-form")
    form_element.scrollIntoView()
  }

  const validate = ({ NameSurname, contact, email, terms }) => {
    const errors = {}
    const addIf = (pred, prop, message) => pred && (errors[prop] = message)

    addIf(!NameSurname, "NameSurname", "Password is required")
    addIf(
      !/^[A-Za-z\s-]+$/.test(NameSurname),
      "NameSurname",
      "Name and Surname must be alphabetic and can include hyphens"
    )

    addIf(!email, "email", "Email is required")
    addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")

    addIf(!contact, "contact", "Contact number is required")
    addIf(!/^(\+?\d+)?$/.test(contact), "contact", "Contact number is invalid")

    addIf(!terms, "terms", "Terms & Conditions is required")

    return errors
  }

  return (
    <StyledLayout location={location}>
      <SEO title={title} location={location} post_id={pageId} seo={seo} />
      <PageBanner>
        <GatsbyImage
          image={graphqlData.banner.childImageSharp.gatsbyImageData}
          alt="Slumberland competition banner"
          // style={{ height: imgHeight, width: imgWidth }}
          imgStyle={{
            // height: imgHeight,
            // width: imgWidth,
            // backgroundColor: "white",
            objectFit: "contain",
          }}
          objectFit="contain"
          objectPosition="50% 50%"
        />
      </PageBanner>
      <InnerContainer>
        <Inner className="grid">
          <Formik
            initialValues={{
              NameSurname: "",
              contact: "",
              email: "",
              terms: false,
              marketing: false,
              q1a: false,
              q1b: false,
              q1c: false,
              q2a: false,
              q2b: false,
              q2c: false,
              q3a: false,
              q3b: false,
              q3c: false,
              q4a: false,
              q4b: false,
              q4c: false,
              q5a: false,
              q5b: false,
              q5c: false,
              q6a: false,
              q6b: false,
              q6c: false,
            }}
            validate={validate}
            onSubmit={async (values) => {
              const dataToSend = {
                NameSurname: values["NameSurname"],
                contact: values["contact"],
                email: values["email"],
                terms: values["terms"],
                marketing: values["marketing"],
                q1a: values["q1a"],
                q1b: values["q1b"],
                q1c: values["q1c"],
                q2a: values["q2a"],
                q2b: values["q2b"],
                q2c: values["q2c"],
                q3a: values["q3a"],
                q3b: values["q3b"],
                q3c: values["q3c"],
                q4a: values["q4a"],
                q4b: values["q4b"],
                q4c: values["q4c"],
                q5a: values["q5a"],
                q5b: values["q5b"],
                q5c: values["q5c"],
                q6a: values["q6a"],
                q6b: values["q6b"],
                q6c: values["q6c"],
              }
              const submitButton = document.getElementById("submitQuiz")
              try {
                submitButton.disabled = true
                const response = await saveSlumberlandCompetitionData(
                  dataToSend
                ).then((response) => {
                  if (response) {
                    document
                      .getElementById("slumberland-competition-form")
                      .reset()
                    alert(
                      "Thank you for entering the Slumberland Tiger Bed Competition."
                    )
                    submitButton.disabled = false
                    setTabIndex(0)
                  } else {
                    alert(
                      "Unknown server response. Please try again. Alternatively please contact support."
                    )
                    submitButton.disabled = false
                  }
                })
              } catch (error) {
                console.error(`Error submitting form: ${error.message}`)
                if (error.message == "User already entered competition.") {
                  alert(
                    "You are already entered into the Slumberland Tiger Bed Competition."
                  )
                } else {
                  alert(
                    "An error has occurred. " +
                      error.message +
                      " Please contact support for more information."
                  )
                }
                submitButton.disabled = false
              }
            }}
          >
            {({ errors, touched }) => (
              <Form
                id="slumberland-competition-form"
                name="slumberland-competition-form"
              >
                <Tabs selectedIndex={tabIndex}>
                  <TabPanel>
                    <BlockWrapOne>
                      <TitleImage>
                        <SlumberlandCompetitionLogo />
                        <GatsbyImage
                          image={
                            graphqlData.tigerBed.childImageSharp.gatsbyImageData
                          }
                          alt="Slumberland competition tiger bed"
                          imgStyle={{
                            objectFit: "contain",
                          }}
                          objectFit="contain"
                          objectPosition="50% 50%"
                        />
                      </TitleImage>
                      <GridLayout>
                        <GridItem>
                          <ItemLogo>
                            <ActivcareProtection />
                          </ItemLogo>
                          <ItemContent>
                            <ItemContentTitle>
                              Activcare Protection
                            </ItemContentTitle>
                            <ItemContentText>
                              <ul>
                                <li>
                                  Added layer of protection keeps sleep surface
                                  hygienically fresh.
                                </li>
                                <li>
                                  Anti-microbial treatment added to the knit
                                  fabric promotes a hygienic sleep environment.
                                </li>
                                <li>
                                  Long-lasting protection against dust mites and
                                  unpleasant odours.
                                </li>
                              </ul>
                            </ItemContentText>
                          </ItemContent>
                        </GridItem>

                        <GridItem>
                          <ItemLogo>
                            <CoolKnitFabric />
                          </ItemLogo>
                          <ItemContent>
                            <ItemContentTitle>
                              Cool Knit Fabric
                            </ItemContentTitle>
                            <ItemContentText>
                              <ul>
                                <li>
                                  The perfect knitted cotton fabric keeps you
                                  cool and dry all night.
                                </li>
                                <li>Soft and flexible to the touch.</li>
                                <li>
                                  Breathable, durable, and anti-bacterial.
                                </li>
                              </ul>
                            </ItemContentText>
                          </ItemContent>
                        </GridItem>

                        <GridItem>
                          <ItemLogo>
                            <ActivecoilSpringSystem />
                          </ItemLogo>
                          <ItemContent>
                            <ItemContentTitle>
                              Activecoil® Spring System
                            </ItemContentTitle>
                            <ItemContentText>
                              <ul>
                                <li>
                                  Advanced spring system actively supports your
                                  body by firming and responding to your
                                  individual movements.
                                </li>
                                <li>
                                  Side-to-Side construction increases sleep
                                  surface and prevents “roll together”.
                                </li>
                              </ul>
                            </ItemContentText>
                          </ItemContent>
                        </GridItem>

                        <GridItem>
                          <ItemLogo>
                            <SurroundActivedgeSystem />
                          </ItemLogo>
                          <ItemContent>
                            <ItemContentTitle>
                              Surround Activedge® System
                            </ItemContentTitle>
                            <ItemContentText>
                              <ul>
                                <li>
                                  Placed along the mattress perimeter to prevent
                                  mattress edges from sagging.
                                </li>
                                <li>
                                  Increases sleeping surface by allowing sleep
                                  right up to the edge of the mattress.
                                </li>
                              </ul>
                            </ItemContentText>
                          </ItemContent>
                        </GridItem>

                        <GridItem>
                          <ItemLogo>
                            <QualityFillingLayers />
                          </ItemLogo>
                          <ItemContent>
                            <ItemContentTitle>
                              Quality Filling Layers
                            </ItemContentTitle>
                            <ItemContentText>
                              <ul>
                                <li>
                                  A combination of multiple luxury foam and
                                  comfortable fibre layers.
                                </li>
                              </ul>
                            </ItemContentText>
                          </ItemContent>
                        </GridItem>
                      </GridLayout>
                      <ButtonContainer>
                        <button
                          className="btn btnQuiz nextStepButton"
                          onClick={() => {
                            GoToNextTab(1)
                          }}
                        >
                          Start Quiz
                        </button>
                      </ButtonContainer>
                      <TermsContainer>
                        <Logo
                          imgStyle={{
                            maxHeight: "50px",
                            height: "50px",
                            margin: "0 auto",
                          }}
                        />
                        <p>
                          Enter the competition between 1 June 2024 and 31
                          August 2024. The prize is a Slumberland Tiger bed set
                          Winners will be announced on 6 September 2024. Terms &
                          Conditions apply.
                        </p>
                        <a href="/terms-and-conditions/">Terms & Conditions</a>
                      </TermsContainer>
                    </BlockWrapOne>
                  </TabPanel>

                  <TabPanel>
                    <BlockWrapTwo>
                      <BlockWrapTwoTitle>
                        <HowToEnterTheCompetition />
                      </BlockWrapTwoTitle>

                      <BlockWrapTwoSteps>
                        <StepItem>
                          <StepItemNumber>
                            <ZeroOne />
                          </StepItemNumber>
                          <StepItemText>
                            LIKE OUR PAGE, COMMENT ON AND SHARE OUR POST.
                          </StepItemText>
                        </StepItem>
                        <StepItem>
                          <StepItemNumber>
                            <ZeroTwo />
                          </StepItemNumber>
                          <StepItemText>
                            REVIEW THE TIGER BED ON OUR WEBSITE AND FOLLOWED BY
                            SHORT QUIZ.
                          </StepItemText>
                        </StepItem>
                        <StepItem>
                          <StepItemNumber>
                            <ZeroThree />
                          </StepItemNumber>
                          <StepItemText>
                            SUBMIT AND GET ENTERED INTO OUR MONTHLY WINNERS’
                            DRAW TO WIN 1 OF 3 SLUMBERLAND TIGER BEDS
                          </StepItemText>
                        </StepItem>
                      </BlockWrapTwoSteps>

                      <BlockTwoQuestions>
                        <BlockTwoQuestionsTitle>
                          <StartTheQuiz />
                        </BlockTwoQuestionsTitle>
                        <QuestionItems>
                          <QuestionItem>
                            <QuestionItemQ>
                              <QuestionItemQNo>1.</QuestionItemQNo>
                              <QuestionItemQText>
                                ONE OF THESE STATEMENTS ABOUT “ACTIVCARE
                                PROTECTION” ARE NOT TRUE:
                              </QuestionItemQText>
                            </QuestionItemQ>
                            <QuestionItemA>
                              <QuestionItemANo>A)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q1a">
                                  LONG-LASTING PROTECTION AGAINST DUST MITES AND
                                  UNPLEASANT ODOURS
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q1a" name="q1a" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>B)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q1b">
                                  A COMBINATION OF MULTIPLE LUXURY FOAM AND
                                  COMFORTABLE FIBRE LAYERS.
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q1b" name="q1b" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>C)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q1c">
                                  ADDED LAYER OF PROTECTION KEEPS SLEEP SURFACE
                                  HYGIENICALLY FRESH.
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q1c" name="q1c" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                          </QuestionItem>

                          <QuestionItem>
                            <QuestionItemQ>
                              <QuestionItemQNo>2.</QuestionItemQNo>
                              <QuestionItemQText>
                                ONE OF THESE STATEMENTS ABOUT “COOL KNIT FABRIC”
                                ARE NOT TRUE:
                              </QuestionItemQText>
                            </QuestionItemQ>
                            <QuestionItemA>
                              <QuestionItemANo>A)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q2a">
                                  BREATHABLE, DURABLE, AND ANTI-BACTERIAL.
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q2a" name="q2a" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>B)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q2b">
                                  PLACED ALONG THE MATTRESS PERIMETER TO PREVENT
                                  MATTRESS EDGES FROM SAGGING.
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q2b" name="q2b" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>C)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q2c">
                                  THE PERFECT KNITTED COTTON FABRIC KEEPS YOU
                                  COOL AND DRY ALL NIGHT.
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q2c" name="q2c" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                          </QuestionItem>

                          <QuestionItem>
                            <QuestionItemQ>
                              <QuestionItemQNo>3.</QuestionItemQNo>
                              <QuestionItemQText>
                                CHOOSE THE FEATURE THAT THIS STATEMENT BELONGS
                                TO:
                                <span>
                                  “A COMBINATION OF MULTIPLE LUXURY FOAM AND
                                  COMFORTABLE FIBRE LAYERS”
                                </span>
                              </QuestionItemQText>
                            </QuestionItemQ>
                            <QuestionItemA>
                              <QuestionItemANo>A)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q3a">
                                  SURROUND ACTIVEDGE SYSTEM
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q3a" name="q3a" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>B)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q3b">QUALITY FILLING LAYERS</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q3b" name="q3b" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>C)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q3c">
                                  ACTIVECOIL SPRING SYSTEM
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q3c" name="q3c" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                          </QuestionItem>

                          <QuestionItem>
                            <QuestionItemQ>
                              <QuestionItemQNo>4.</QuestionItemQNo>
                              <QuestionItemQText>
                                CHOOSE THE FEATURE THAT THIS STATEMENT BELONGS
                                TO:
                                <span>
                                  “INCREASES SLEEPING SURFACE BY SLEEP RIGHT UP
                                  TO THE EDGE OF THE MATTRESS.”
                                </span>
                              </QuestionItemQText>
                            </QuestionItemQ>
                            <QuestionItemA>
                              <QuestionItemANo>A)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q4a">QUALITY FILLING LAYERS</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q4a" name="q4a" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>B)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q4b">ACTIVCARE PROTECTION</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q4b" name="q4b" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>C)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q4c">
                                  SURROUND ACTIVEDGE SYSTEM
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q4c" name="q4c" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                          </QuestionItem>

                          <QuestionItem>
                            <QuestionItemQ>
                              <QuestionItemQNo>5.</QuestionItemQNo>
                              <QuestionItemQText>
                                ONE OF THESE FEATURES DO NOT BELONG TO THE
                                SLUMBERLAND TIGER BED:
                              </QuestionItemQText>
                            </QuestionItemQ>
                            <QuestionItemA>
                              <QuestionItemANo>A)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q5a">QUALITY FILLING LAYERS</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q5a" name="q5a" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>B)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q5b">ACTIVCARE PROTECTION</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q5b" name="q5b" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>C)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q5c">AFFINITY APPLE FRESH</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q5c" name="q5c" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                          </QuestionItem>

                          <QuestionItem>
                            <QuestionItemQ>
                              <QuestionItemQNo>6.</QuestionItemQNo>
                              <QuestionItemQText>
                                ONLY ONE OF THESE FEATURES BELONGS TO THE
                                SLUMBERLAND TIGER BED:
                              </QuestionItemQText>
                            </QuestionItemQ>
                            <QuestionItemA>
                              <QuestionItemANo>A)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q6a">INTELLIGENT FOAMS</label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q6a" name="q6a" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>B)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q6b">
                                  ACTIVECOIL SPRING SYSTEM
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q6b" name="q6b" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                            <QuestionItemA>
                              <QuestionItemANo>C)</QuestionItemANo>
                              <QuestionItemAText>
                                <label for="q6c">
                                  AFFINITY FOAM EDGE SYSTEM
                                </label>
                              </QuestionItemAText>
                              <QuestionItemACheckbox>
                                <Field type="checkbox" id="q6c" name="q6c" />
                              </QuestionItemACheckbox>
                            </QuestionItemA>
                          </QuestionItem>
                        </QuestionItems>
                      </BlockTwoQuestions>

                      <BlockTwoButton>
                        <button
                          className="btn btnQuiz nextStepButton"
                          onClick={() => {
                            GoToNextTab(2)
                          }}
                        >
                          Continue
                        </button>
                      </BlockTwoButton>
                    </BlockWrapTwo>
                  </TabPanel>

                  <TabPanel>
                    <BlockWrapThree>
                      <BlockWrapThreeTitle>
                        <FillInForm />
                      </BlockWrapThreeTitle>
                      <BlockWrapThreeForm>
                        <FormField>
                          <FormFieldLabel>
                            <label
                              for="NameSurname"
                              className={errors.NameSurname ? "error" : ""}
                            >
                              NAME & SURNAME
                            </label>
                          </FormFieldLabel>
                          <FormFieldInput>
                            <Field
                              type="text"
                              id="NameSurname"
                              name="NameSurname"
                              className={errors.NameSurname ? "error" : ""}
                            />
                          </FormFieldInput>
                        </FormField>
                        <FormField>
                          <FormFieldLabel>
                            <label
                              for="contact"
                              className={errors.contact ? "error" : ""}
                            >
                              CONTACT NUMBER
                            </label>
                          </FormFieldLabel>
                          <FormFieldInput>
                            <Field
                              type="text"
                              id="contact"
                              name="contact"
                              className={errors.contact ? "error" : ""}
                            />
                          </FormFieldInput>
                        </FormField>
                        <FormField>
                          <FormFieldLabel>
                            <label
                              for="email"
                              className={errors.email ? "error" : ""}
                            >
                              EMAIL
                            </label>
                          </FormFieldLabel>
                          <FormFieldInput>
                            <Field
                              type="text"
                              id="email"
                              name="email"
                              className={errors.email ? "error" : ""}
                            />
                          </FormFieldInput>
                        </FormField>
                      </BlockWrapThreeForm>
                      <BlockWrapThreeCheckboxes>
                        <CheckboxGroup>
                          <Field
                            type="checkbox"
                            id="terms"
                            name="terms"
                            className={errors.terms ? "error" : ""}
                          />
                          <label
                            for="terms"
                            className={errors.terms ? "error" : ""}
                          >
                            I agree to the terms & conditions.
                          </label>
                        </CheckboxGroup>
                        <CheckboxGroup>
                          <Field
                            type="checkbox"
                            id="marketing"
                            name="marketing"
                          />
                          <label for="marketing">
                            I agree to receive personalised marketing emails.
                          </label>
                        </CheckboxGroup>
                      </BlockWrapThreeCheckboxes>
                      <BlockWrapThreeSubmit>
                        <button
                          id="submitQuiz"
                          className="btn btnQuiz submitQuiz"
                          type={"submit"}
                        >
                          Submit
                        </button>
                      </BlockWrapThreeSubmit>
                    </BlockWrapThree>
                  </TabPanel>
                </Tabs>
              </Form>
            )}
          </Formik>
        </Inner>
      </InnerContainer>
    </StyledLayout>
  )
}

// ====================
//        STYLES
// ====================
const SlumberlandContainer = styled.div`
  padding: 0px 40px 60px 40px;
  max-width: 1280px;
`

const StyledLayout = styled(Layout)`
  background: #162232;
  .black-friday-content,
  h1,
  h2,
  h3 {
    color: #9eb5d2;
    margin: auto;
    text-align: center;
  }
  @font-face {
    font-family: "Binjay";
    font-display: swap;
    src: url(/fonts/Binjay.ttf) format("ttf");
  }
  @font-face {
    font-family: "Engravers Gothic BT Font";
    font-display: swap;
    src: url(/fonts/Engravers-Gothic-BT-Font.ttf) format("ttf");
  }
  @font-face {
    font-family: "Lato-Black";
    font-display: swap;
    src: url(/fonts/Lato-Black.ttf) format("ttf");
  }
  @font-face {
    font-family: "Lato-Bold";
    font-display: swap;
    src: url(/fonts/Lato-Bold.ttf) format("ttf");
  }
  @font-face {
    font-family: "Lato-Light";
    font-display: swap;
    src: url(/fonts/Lato-Light.ttf) format("ttf");
  }
  @font-face {
    font-family: "Lato";
    font-display: swap;
    src: url(/fonts/Lato-Regular.ttf) format("ttf");
  }
  @font-face {
    font-family: "MyriadPro";
    font-display: swap;
    src: url(/fonts/MyriadPro-Regular.otf) format("otf");
  }
`

const PageBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 10px solid #fcb105;
  font-size: 14px;
`
const InnerContainer = styled.div`
  background-color: #071322;
  color: #fff;
  width: 100%;
`
const BlockWrapOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BlockWrapTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BlockWrapTwoTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 60px;
  margin-bottom: 60px;
`

const BlockWrapTwoSteps = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 675px) {
    grid-template-columns: 1fr;
  }
`

const BlockTwoQuestions = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`

const BlockTwoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 15px;
  button {
    background-color: #fcb105;
    color: #fff;
    border-radius: 10px;
    padding: 15px 60px;
    font-size: 20px;
    cursor: pointer;
  }
`

const BlockTwoQuestionsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 50%;
  margin-bottom: 30px;
  align-self: start;
`

const QuestionItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 30px;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 675px) {
    grid-template-columns: 1fr;
  }
`

const QuestionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 30px;
`

const QuestionItemQ = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 20px 1fr;
`

const QuestionItemQNo = styled.div`
  color: #fff;
`

const QuestionItemQText = styled.div`
  color: #fcb105;
  font-size: 14px;
  span {
    display: block;
    color: #fff;
  }
`

const QuestionItemA = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 20px 1fr 20px;
  width: 100%;
`

const QuestionItemANo = styled.div`
  color: #fff;
`

const QuestionItemAText = styled.div`
  font-size: 14px;
  color: #fff;
`

const QuestionItemACheckbox = styled.div``

const StepItem = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
`

const StepItemNumber = styled.div``

const StepItemText = styled.div`
  color: #fff;
  height: 100%;
  border-bottom: 3px solid #00ad11;
  font-size: 14px;
  padding-bottom: 10px;
`

const TitleImage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  place-items: center;
  gap: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 675px) {
    grid-template-columns: 1fr;
  }
`
const GridItem = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 10px;
`
const ItemLogo = styled.div``
const ItemContent = styled.div``
const ItemContentTitle = styled.div`
  color: #fff;
  background-color: #824e95;
  padding: 5px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 10px;
`
const ItemContentText = styled.div`
  color: #fff;
  ul {
    list-style: none;
    padding-left: 20px;
    li {
      color: #fff;
      position: relative;
      font-size: 14px;
      &:before {
        content: "-";
        position: absolute;
        left: -15px;
      }
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  button {
    background-color: #fcb105;
    color: #fff;
    border-radius: 10px;
    padding: 15px 60px;
    font-size: 20px;
    cursor: pointer;
  }
`

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  max-width: 800px;
  text-align: center;
  gap: 25px;
  margin-bottom: 25px;
  margin-top: 25px;
  font-size: 14px;
  img {
    max-height: 50px;
  }
  a {
    font-size: 14px;
    color: #fff;
    background-color: #824e95;
    padding: 5px 10px;
    border-radius: 10px;
  }
`

const BlockWrapThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const BlockWrapThreeTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 50%;
  margin: 60px 0;
  align-self: start;
`

const BlockWrapThreeForm = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
`
const FormField = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-items: start;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`
const FormFieldLabel = styled.div`
  width: 100%;
  label {
    font-size: 20px;
    &.error {
      text-align: initial;
      padding: 0;
      background: transparent;
      border: none;
      color: #ed1c24;
    }
  }
`
const FormFieldInput = styled.div`
  width: 100%;
  input {
    width: 100%;
    padding: 10px;
    transition: 0.2s all ease-in-out;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
    border: none;
    &:hover,
    &:focus {
      box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
    }
    &.error {
      text-align: initial;
      background: white;
      border: none;
      border-radius: 2px;
      width: 100%;
      padding: 10px;
      outline: none;
      box-shadow: none;
    }
  }
`

const BlockWrapThreeCheckboxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`

const CheckboxGroup = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: 20px 1fr;
  margin-right: 25px;
  gap: 10px;
  &:last-of-type {
    margin-right: 0;
  }
  label {
    &.error {
      text-align: initial;
      padding: 0;
      background: transparent;
      border: none;
      color: #ed1c24;
    }
  }
`

const BlockWrapThreeSubmit = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  margin: 30px 0;
  button {
    background-color: #fcb105;
    color: #fff;
    border-radius: 10px;
    padding: 15px 60px;
    font-size: 20px;
    cursor: pointer;
  }
`

// ==================
//        QUERY
// ==================

const SLUMBERLAND_COMPETITION_PAGE_QUERY = graphql`
  {
    ## Page Data
    localWpGraphQlPages(slug: { eq: "slumberland-tiger-bed-competition" }) {
      pageId
      title
      content
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
    }

    banner: file(
      relativePath: { eq: "slumberland-competition/rest_to_roar_banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }

    tigerBed: file(
      relativePath: { eq: "slumberland-competition/tiger_bed.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default SlumberlandCompetitionPage
