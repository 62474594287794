import React from "react"

const SurroundActivedgeSystem = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 94.72 124.54"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <circle
              className="cls-1"
              cx="47.36"
              cy="77.19"
              r="46.36"
              stroke="#8a4e9e"
              strokeDasharray="0 0 11 11"
              strokeMiterlimit="10"
              strokeWidth="2px"
              fill="none"
            />
            <circle
              className="cls-4"
              strokeWidth="0px"
              fill="#8a4e9e"
              cx="47.36"
              cy="77.19"
              r="38.23"
            />
          </g>
          <g>
            <polyline
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              points="36.85 63.93 47.27 66.56 47.27 69.06"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="50.06"
              y1="66.19"
              x2="58.79"
              y2="63.93"
            />
            <polyline
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              points="33.71 67.53 47.27 71.28 60.83 67.53"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="33.71"
              y1="70.49"
              x2="44.75"
              y2="73.63"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="49.51"
              y1="73.63"
              x2="60.83"
              y2="70.49"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M25.94,60.28l7.76,2.22s6.05-3.68,13.56-3.68c7.9,0,13.56,3.68,13.56,3.68l7.95-2.22"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="60.83"
              y1="62.5"
              x2="60.83"
              y2="91.74"
            />
            <polyline
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              points="68.45 89.61 47.36 95.55 25.94 89.61"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="67.13"
              y1="60.71"
              x2="67.13"
              y2="89.96"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="33.71"
              y1="62.5"
              x2="33.71"
              y2="91.76"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="27.27"
              y1="60.66"
              x2="27.27"
              y2="89.96"
            />
            <polyline
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              points="33.71 86.9 47.2 90.77 60.83 87.06"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M40.76,85.4c0,.57-.99.94-1.81.94s-1.48-.46-1.48-1.03v-10.45"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M43.96,85.97c0,.57-.91.93-1.73.93s-1.48-.46-1.48-1.03v-9.5"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M47.2,87.41c0,.57-.94.83-1.75.83s-1.48-.46-1.48-1.03v-9.67"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M53.65,85.49c0,.57.99.94,1.81.94s1.48-.46,1.48-1.03v-10.45"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M50.45,86.06c0,.57.91.93,1.73.93s1.48-.46,1.48-1.03v-9.5"
            />
            <path
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              d="M47.2,87.41c0,.57.96.92,1.77.92.82,0,1.48-.46,1.48-1.03v-9.67"
            />
            <line
              className="cls-2"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".74px"
              x1="47.2"
              y1="78.58"
              x2="47.2"
              y2="87.41"
            />
          </g>
        </g>
        <circle
          className="cls-3"
          fill="#a1aab0"
          strokeWidth="0px"
          cx="49.56"
          cy="23.44"
          r="23.44"
        />
      </g>
    </g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      className="cls-5"
      strokeWidth="0px"
      fill="#fff"
      d="M56.3,26h3v3.5c0,.3-.1.6-.3.8-.2.2-.5.3-.9.3h-1.8v6.7h-5.4v-6.7h-11.7c-.4,0-.7-.1-1-.4-.3-.2-.5-.5-.6-.9l-.6-3,13.4-18h5.9v17.7ZM50.9,18.4c0-.5,0-1,0-1.5s0-1.1.1-1.7l-7.7,10.7h7.5v-7.6Z"
    />
  </svg>
)

export default SurroundActivedgeSystem
